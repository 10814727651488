<template>
  <div class="container">
    <div class="header"><router-link to="/user"><img src="../assets/images/back.png" alt=""></router-link> 活动 <router-link to="/myrecord" class="right">我的记录</router-link></div>
    <div class="blian22"></div>
    <div class="activity">
      <div class="activity-list">
        <router-link to="/myrecordedit">
        <img :src="activeInfo.content" alt="">
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
	import { activity } from "@/assets/js/api";
export default {
  name: "myactivity",
  data() {
    return {
    	activeInfo:{},
    }
  },
  methods: {
  	//获取图书列表
			getactivity(){
				activity({}).then(res => {
					if(res.code == 0) {
						this.activeInfo = res.activity;
					}
				});
			}
  },
  mounted() {
  	this.getactivity()
  }
};
</script>

